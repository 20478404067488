<template>
  <v-card flat>
    <v-container>
        <v-row justify="center">
          <p class="text-h5"> {{$t('Station settings')}}</p>
        </v-row>
        <v-row justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            :label="$t('Station name')"
            v-model="station.stn_name"
            counter="20"
          ></v-text-field>
        </v-col>
        </v-row>
        <v-row justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            :label="$t('Station serial number')"
            v-model="station.serial_no"
            counter="20"
          ></v-text-field>
        </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              :label="$t('Station code')"
              v-model="station.code"
              counter="20"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-switch
              v-model="station.bosfcc_config"
              :label="$t(keybLabel)"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-switch
              v-model="station.role"
              :label="$t(singleLabel)"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
              >{{ $t("save") }}</v-btn
            >
          </v-col>
        </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Station",
  data() {
    return {
      station: {},
      loading: false,
      keyboard: false,
      singleRegime: false,
    };
  },
  methods: {
    async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/system")
          this.station = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        res = await this.$http.post(`/system`, this.station);
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
        this.$store.commit('SET_STATION',res.data)
      }
    },
  },
  computed: {
    keybLabel () {
      return !this.station.bosfcc_config ? "virtual_kb" : "hw_keyb"
    },
    singleLabel () {
      return this.station.role ? "single_reg_yes" : "single_reg_no"
    }
  },
  created () {
      this.initialize()
    },
};
</script>
