<template>
  <v-data-table :headers="headers" :items="vehicles" class="elevation-1" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" >
        <v-toolbar-title class="white--text">{{ $t("Vehicles") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dark
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="$auth.user().roles === '1'" color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Vehicle") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black"  dark>
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-car</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>

            <v-card-text >
              <v-form v-model="valid" refs="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.user_data1"
                      :label="$t('Prg. Vehicle ID')"
                      :counter="50"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.plate"
                      :label="$t('Device Vehicle ID')"
                      counter="80"
                     
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.name"
                      :label="$t('Vehicle ID')"
                      :counter="45"
                   
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.string3"
                      :label="$t('Vehicle Description 1')"
                      :counter="45"
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.string4"
                      :label="$t('Vehicle Description 2')"
                      :counter="45"
                    ></v-text-field>
                  </v-col>
                  
                
                  <v-col cols="12">
                    <v-radio-group 
                      v-model.number="editedItem.driver_required"
                      :rules="[v => v !== null || $t('You have to choose one')]"
                    >
                      <template v-slot:label>
                        <div>{{$t('Select authorization type')}}</div>
                      </template>
                      <v-radio :value="5">
                        <template v-slot:label>
                          <div>
                            <v-icon    color="teal"> mdi-car </v-icon>
                            <v-icon  color="teal"> mdi-account</v-icon>
                            <span class="ml-2 mt-1">{{$t('with Driver')}}</span>
                          </div>

                        </template>
                      </v-radio>
                      <v-radio :value="1">
                        <template v-slot:label>
                          <div>
                            <v-icon    color="purple"> mdi-car </v-icon>
                            <span class="ml-2 mt-1">{{$t('only Car')}}</span>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.user_data2"
                        :label="$t('Note required')"
                        color="indigo darken-3"
                        true-value="1"
                        false-value="0"
 
                      ></v-checkbox>
                      </v-col>
                       <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.user_data3"
                        :label="$t('KM/EH required')"
                        color="indigo darken-3"
                        true-value="1"
                        false-value="0"
                        
                      ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.type"
                        :label="$t('Installed Vehicle')"
                        color="indigo darken-3"
                        :true-value="3"
                        :false-value="2"
                        
                      ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.status"
                        :label="$t('Active')"
                        color="indigo darken-3"
                        :true-value="2"
                        :false-value="1"
                        
                      ></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.use_pin_code"
                        :label="$t('use_pin_code')"
                        color="indigo darken-3"
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                      </v-col>

                      <v-col v-show="editedItem.use_pin_code > 0" cols="6">
                        <v-text-field
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="numberRule"
                          :type="show1 ? 'text' : 'password'"
                          dense
                          outlined
                          v-model="editedItem.pin_code"
                          :label="$t('pin_code')"
                          @click:append="show1 = !show1"
                          style="padding-top: 0.5rem;"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close">{{$t('Cancel')}}</v-btn>
               <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="!valid"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text  :loading="loading2" @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.driver_required="{ item }">
        <div v-if="item.driver_required == 5">
          <v-icon    color="teal"> mdi-car </v-icon>
          <v-icon  color="teal"> mdi-account</v-icon>
        </div>
        <div v-else>
          <v-icon    color="purple"> mdi-car </v-icon>
        </div>
    </template>
    
    <template v-slot:item.type="{ item }">
        <v-icon v-if="item.type === 3"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status > 1"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  v-show="$auth.user().roles === '1'" color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  v-show="$auth.user().roles === '1'" color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "vehicles",

  data() {
    return {
        show1: false,
        dialog: false,
        dialogDelete: false,
        vehicles: [],
        valid: false,
        loading: false,
        loading2: false,
        rules: [
          v => v !== ''||  this.$t('Item is required'),
          v => v.length <= 50 || this.$t('A maximum  characters exceeded')
        ],
        numberRule: [v => (!isNaN(parseInt(v)) && v >= 0 && v <= 99999 || v === "" || v === null) ||  this.$t('Number has to be between 0 and 99999')],
        editedItem: {
          MeansString: '',
          user_data1: '',
          plate: '',
          name: '',
          string2: '',
          string3: '',
          string4:'',
          driver_required: null,
          type: 2,
          status: 2,
          user_data2: '0',
          user_data3: '0',
          fleet_id: 0,
          use_pin_code: 0,
          pin_code: ''
        },
        defaultItem: {
          MeansString: '',
          user_data1: '',
          plate: '',
          name: '',
          string2: '',
          string3: '',
          string4:'',
          driver_required: null,
          type: 2,
          status: 2,
          user_data2: '0',
          user_data3: '0',
          fleet_id: 0,
          use_pin_code: 0,
          pin_code: ''

        },
        editedIndex: -1,
        search: '',
        headers: [
          {
            text: this.$t('Prg. Vehicle ID'),
            align: 'start',
            sortable: true,
            value: 'user_data1',
          },
          {
            text: this.$t('Device Vehicle ID'),
            align: 'start',
            sortable: true,
            value: 'plate',
          },
          {
            text: this.$t('Vehicle ID'),
            align: 'center',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('Vehicle Desc. 1'),
            align: 'center',
            sortable: true,
            value: 'string3',
          },
          {
            text: this.$t('Vehicle Desc. 2'),
            align: 'center',
            sortable: true,
            value: 'string4',
          },
          {
            text: this.$t('Auth Type'),
            align: 'center',
            sortable: true,
            value: 'driver_required',
          },
          {
            text: this.$t('Instaled Vehicle'),
            align: 'center',
            sortable: true,
            value: 'type',
          },
          {
            text: this.$t('Active'),
            align: 'center',
            sortable: true,
            value: 'status',
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
          },
        
        
        ]
        
    }
  },
  methods: {
      async initialize () {
        try {
          this.loading = true
          const res = await this.$http.get("/vehicle")
          this.vehicles = res.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
    },
    
    editItem (item) {
      this.editedIndex = this.vehicles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.vehicles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = {};
      try {
          res = await this.$http.delete(`/vehicle/${this.editedItem.id}`)
          this.vehicles.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.closeDelete()
        } 
      
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          this.editedItem.MeansString = this.editedItem.user_data1
          res = await this.$http.put("/vehicle", this.editedItem)
          Object.assign(this.vehicles[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
        
      } else {
        try {
          this.loading2 = true
          if (this.vehicles.some(vehicle => vehicle.user_data1 === this.editedItem.user_data1)) {
            throw new Error(this.$t('Same string exists'));
          }
          this.editedItem.MeansString = this.editedItem.user_data1
          await this.$http.post("/vehicle", this.editedItem)
          this.vehicles.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
    },
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add new Vehicle') : this.$t('Edit Vehicle')
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>
